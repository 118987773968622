















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'popo-info': () => import('@/modules/popo/components/details/popo-info.vue'),
    'attach-nft-section': () => import('@/modules/popo/components/details/attach-nft-section.vue'),
    'popo-activity-history': () => import('@/modules/popo/components/details/popo-activity-history.vue'),
  },
})
export default class extends Vue {
  dialog = false
}
